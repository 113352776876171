import React from "react"
import PropTypes from "prop-types"
import Link from "@components/link"
import SVG from "@components/svg"

import * as styles from "./styles.module.scss"

const Breadcrumbs = ({ links }) => {
  const capitalize = (link) => {
    const arr = link.split(" ")
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    }
    return arr.join(" ")
  }
  return (
    <div className={styles.breadcrumbs}>
      <ul>
        <li>
          <Link to="/">
            <SVG name="home-icon" />{" "}
          </Link>
        </li>
        {links.map((link, index) => {
          return (
            <li key={index}>
              {"/ "}
              <Link to={link.url}>{capitalize(link.label)}</Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Breadcrumbs.propTypes = {
  links: PropTypes.array
}

Breadcrumbs.defaultProps = {}

export default Breadcrumbs
