import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import SwiperCore, { A11y, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"

import Image from "@components/image"
import SVG from "@components/svg"

import * as styles from "./styles.module.scss"

SwiperCore.use([Navigation, A11y])

const Gallery = ({ items }) => {
  const [selectedItem, setSelectedItem] = useState(0)

  const selectItem = (index) => {
    setSelectedItem(index)
  }

  const slideChange = (swiper) => {
    setSelectedItem(swiper.activeIndex)
  }

  return (
    <div className={styles.gallery}>
      <div
        className={classNames(styles.galleryItems, {
          [styles.swiper]: items.length > 4
        })}
      >
        {items.length <= 4 &&
          items.map((item, index) => {
            const imgClasses = classNames(styles.image, {
              [styles.selected]: selectedItem === index
            })

            return (
              <div
                key={index}
                onClick={() => selectItem(index)}
                onKeyPress={() => selectItem(index)}
                role="button"
                tabIndex={0}
                className={imgClasses}
              >
                <Image src={item.image} />
              </div>
            )
          })}

        {items.length > 4 && (
          <Swiper
            a11y
            allowTouchMove={true}
            slidesPerView={4}
            spaceBetween={0}
            navigation={{
              nextEl: "#next-slide",
              prevEl: "#prev-slide"
            }}
            direction="vertical"
            onSlideChange={(swiper) => slideChange(swiper)}
            onSwiper={(swiper) => slideChange(swiper)}
          >
            {items.map((item, index) => {
              const imgClasses = classNames(styles.image, styles.slide, {
                [styles.selected]: selectedItem === index
              })

              return (
                <SwiperSlide key={index}>
                  <div
                    onClick={() => selectItem(index)}
                    onKeyPress={() => selectItem(index)}
                    role="button"
                    tabIndex={0}
                    className={imgClasses}
                  >
                    <Image src={item.image} />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}

        {items.length > 4 && (
          <div className={styles.arrows}>
            <button id="prev-slide">
              <SVG name="angle-left" />
            </button>
            <button id="next-slide">
              <SVG name="angle-right" />
            </button>
          </div>
        )}
      </div>
      <div
        className={classNames(styles.galleryHighlight, {
          [styles.swiper]: items.length > 4
        })}
      >
        <Image className={styles.imageHightlight} src={items[selectedItem].image} />
      </div>
    </div>
  )
}

Gallery.propTypes = {
  /**
   * An array of data used to render the Gallery's items.
   */
  items: PropTypes.array.isRequired
}

Gallery.defaultProps = {}

export default Gallery
