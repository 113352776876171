import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { DebugMediaQueries } from "@plugins/gatsby-ample-debuggers"
import SEO from "@layout/seo"
import TriangleBackground from "@layout/triangle-background"

const Layout = ({ children, className, seoMetaTags, useTriangleBackground }) => (
  <>
    <SEO metaTags={seoMetaTags.tags} />
    <div style={{ position: "relative" }}>
      <main
        className={classNames({
          [className]: className
        })}
      >
        {children}
      </main>
      <TriangleBackground shouldDisplay={useTriangleBackground} noHeaderFooter={true} />
    </div>
    <DebugMediaQueries isShowing={process.env.GATSBY_DEBUG_MEDIA_QUERIES} />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * Additional classes on the main element.
   */
  className: PropTypes.string,
  /**
   * SEO Meta Tags for the page
   */
  seoMetaTags: PropTypes.shape({
    tags: PropTypes.array
  }),
  /**
   * If the triangle background should be used.
   */
  useTriangleBackground: PropTypes.bool
}

Layout.defaultProps = {
  seoMetaTags: {
    tags: []
  },
  useTriangleBackground: false
}

export default Layout
