export default {
  props: {
    links: [
      {
        label: "vans",
        url: "/vans"
      }
    ]
  }
}
