import imageFixture from "../image/fixtures"

export default {
  props: {
    items: [
      {
        image: imageFixture.detail_1.src
      },
      {
        image: imageFixture.image_1.src
      },
      {
        image: imageFixture.image_2.src
      },
      {
        image: imageFixture.detail_2.src
      }
    ]
  },
  withSlider: {
    items: [
      {
        image: imageFixture.detail_1.src
      },
      {
        image: imageFixture.image_1.src
      },
      {
        image: imageFixture.image_2.src
      },
      {
        image: imageFixture.detail_2.src
      },
      {
        image: imageFixture.detail_3.src
      },
      {
        image: imageFixture.detail_4.src
      }
    ]
  }
}
