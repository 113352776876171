import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { component as Footer } from "@layout/footer"
import { component as Header } from "@layout/header"

import Layout from "@layout/no-header-footer"
import Grid from "@layout/grid"
import Card from "@components/card"
import Carousel from "@components/carousel"
import Image from "@components/image"
import Breadcrumbs from "@components/breadcrumbs"
import Gallery from "@components/gallery"
import Button from "@components/button"
import Container from "@layout/container"

import * as styles from "./styles.module.scss"

class ProductDetailPage extends React.Component {
  // data alias
  page = this.props.data.datoCmsProductTruckVan
  section = this.props.pageContext.section
  category = this.props.pageContext.category

  getProductsSection() {
    return (
      <Grid items={`${this.page.relatedProductObjects.length}`} hasChildren gutter="15" layout="3">
        {this.page.relatedProductObjects.map((product, index) => {
          const { title, section, category, permalink, images } = product
          const categoryLink = `/${section.permalink}/${category.permalink}`
          const cardData = {
            body: `<a href="${categoryLink}">${category.title}</a>`,
            heading: title,
            image: {
              childImageSharp: {
                gatsbyImageData: images ? images[0].gatsbyImageData : undefined
              }
            },
            theme: "media",
            url: `${categoryLink}/${permalink}`
          }

          return <Card key={index} {...cardData} />
        })}
      </Grid>
    )
  }

  getInstallationGalleryCarousel() {
    return (
      <div style={{ marginTop: "90px" }}>
        <Carousel theme="default" title="Installation Gallery" leftAlignTitle={true}>
          {this.page.installationGallery.map((item, index) => {
            if (item.videoIframe) {
              return (
                <div
                  key={index}
                  isiframe={true}
                  dangerouslySetInnerHTML={{
                    __html: item.videoIframe
                  }}
                ></div>
              )
            }

            return (
              <Image
                key={index}
                src={{
                  childImageSharp: {
                    gatsbyImageData: {
                      ...item.image.gatsbyImageData
                    }
                  }
                }}
              />
            )
          })}
        </Carousel>
      </div>
    )
  }

  getBrandsCarousel() {
    const brands = this.page.brandsCarousel

    if (brands) {
      return (
        <div>
          <Carousel theme={brands.theme} title="Brands" leftAlignTitle={true}>
            {brands.slides.map((slide, index) => {
              const image = {
                childImageSharp: {
                  gatsbyImageData: {
                    ...slide.image.gatsbyImageData
                  }
                }
              }
              return <Image key={index} src={image} link={slide.linkTo} />
            })}
          </Carousel>
        </div>
      )
    }

    return null
  }

  render() {
    const galleryItems = this.page.images.map((image) => {
      return {
        image: {
          childImageSharp: {
            gatsbyImageData: {
              ...image.gatsbyImageData
            }
          }
        }
      }
    })

    const bodyContent =
      this.page.bodyNode && this.page.bodyNode.childMarkdownRemark
        ? this.page.bodyNode.childMarkdownRemark.html
        : this.page.body

    return (
      <>
        <Helmet>
          <html lang="en" />
        </Helmet>

        <Header {...this.props.data.menu} />

        <Layout seoMetaTags={this.page.seoMetaTags}>
          <div className={styles.productDetail}>
            <Breadcrumbs
              links={[
                { label: this.section.title, url: `/${this.section.permalink}` },
                {
                  label: this.category.title,
                  url: `/${this.section.permalink}/${this.category.permalink}`
                }
              ]}
            />
            <Grid items="2" hasChildren gutter="15" layout="2">
              <Gallery items={[...galleryItems]}></Gallery>
              <div>
                {this.page.title && <h1 style={{ fontSize: "34px" }}>{this.page.title}</h1>}
                {this.page.brand && (
                  <div className={styles.brandBtn}>
                    <Button
                      theme="arrow-without-padding"
                      to={`/brands/${this.page.brand.permalink}`}
                    >
                      {this.page.brand.title}
                    </Button>
                  </div>
                )}
                {this.page.body && (
                  <div
                    className={styles.productDetailCopy}
                    dangerouslySetInnerHTML={{
                      __html: bodyContent
                    }}
                  ></div>
                )}
                <h4>Interested in these products?</h4>
                <Button to="/contact">Email, Call, or Visit</Button>
              </div>
            </Grid>

            {this.getInstallationGalleryCarousel()}
          </div>
        </Layout>

        <div style={{ marginBottom: "-12px" }}>
          <Container hasChildren theme="background-is-gray" greenBorder={true}>
            {this.page.relatedProductObjects.length > 0 && (
              <>
                <h2 style={{ marginBottom: "30px" }}>Related Products</h2>
                <div style={{ marginBottom: "50px" }}>{this.getProductsSection()}</div>
              </>
            )}
            {this.getBrandsCarousel()}
          </Container>
        </div>

        <Footer {...this.props.data.footer} />
      </>
    )
  }
}

export default ProductDetailPage

export const query = graphql`
  query ProductTruckVanQuery($permalink: String!) {
    ...MegaMenu
    ...Footer

    datoCmsProductTruckVan(permalink: { eq: $permalink }) {
      permalink
      title
      brand: productBrand {
        title
        permalink
      }
      seoMetaTags {
        tags
      }
      body
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
      images {
        gatsbyImageData
      }
      installationGallery {
        image {
          gatsbyImageData
        }
        videoIframe
      }
      relatedProductObjects {
        title
        permalink
        category: productCategory {
          title
          permalink
        }
        section: productSection {
          permalink
        }
        images {
          gatsbyImageData(aspectRatio: 1.5)
        }
      }
      brandsCarousel {
        theme
        title
        slides {
          ... on DatoCmsImageOld {
            linkTo
            image {
              gatsbyImageData
            }
          }
          ... on DatoCmsContent {
            body
          }
        }
      }
    }
  }
`
